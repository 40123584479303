.backgroundTracking {
    background-image: url('../../assets/images/homepage/cover/circle.svg');
    background-repeat: no-repeat;
    background-position: top 280px center;
    background-size: 100%;
}
.ToolDetailWrapper{
    background-color: #fff;
    z-index: 5;
    left: 0;
    position: absolute;
    -webkit-box-shadow: 4px 0 10px -4px rgba(0, 0, 0, 0.2);
    -moz-box-shadow: 4px 0 10px -4px rgba(0, 0, 0, 0.2);
    box-shadow: 4px 0 10px -4px rgba(0, 0, 0, 0.2);
}
.subToolWrapper::-webkit-scrollbar{
    width: 8px;
}
.subToolWrapper::-webkit-scrollbar-thumb{
    background-color: #ccc;
    border-radius: 8px;
}
.subToolWrapper::-webkit-scrollbar-track{
    background-color: transparent; 
}
#mainShipmentDetail .subDetailWrapper{
    position: relative;
    background-color: #fff;
    float: left;
    width: 420px;
    border-right: 1px solid #E3E3E3;
    box-shadow:none;
    overflow-x: hidden;
    overflow-y: auto;
}
#mainShipmentDetail .subDetailWrapper.open{
    left: 420px;
}
.MapDetailWrapper{
    position:fixed;
    right: 0;
    width: calc(100% - 840px);
    height: calc(100vh - 100px);
}

/* boxPosition */
  .boxPosition{
    margin-top: 20px;
    padding-top: 20px;
  }
  .boxPosition .boxItem {
      position: relative;
      padding-left: 30px;
      border-left: 2px dotted #E3E3E3;
      margin-bottom: 20px;
      margin-left: 20px;
  }
  .boxPosition .boxItem:last-child{
    border-left: 2px dotted transparent;
  }
  .boxPosition .boxItem i {
      position: absolute;
      top: -15px;
      left: -10px;
      font-size: 16px;
      color: #8B8B8B;
  }
  .boxPosition .boxItem .content{
    padding: 0;
    position: relative;
    top: -20px;
  }
  .boxPosition .boxItem .content .title{
    
  }
  .boxPosition .boxItem .content .title.boderBottom{
    border-bottom: 1px solid #E3E3E3;
    padding-bottom: 15px;
    margin-bottom: 10px;
  }
  .boxPosition .boxItem .content .status{
    color: #8B8B8B;
    font-size: .9em;
  }
  .boxPosition .boxItem .content .detail{
  }
  .boxPosition.tracking .boxItem .content .detail{
      margin-top: 15px;
  }
  .boxPosition .boxItem .content .detail .img_wrapper{
      width: 90px;
      float: left;
      text-align: center;
      margin-right: 10px;
  }
  .boxPosition .boxItem .content .detail .img{
      -webkit-box-sizing: content-box;
      box-sizing: content-box;
      overflow: hidden;
      position: relative;
      width: 90px;
      height: 90px;
      margin-bottom: 10px;
  }
  
  .boxPosition .boxItem .content .detail .img img{
      -o-object-fit: cover;
      object-fit: cover;
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: -9999px;
      bottom: -9999px;
      left: -9999px;
      right: -9999px;
      margin: auto;
  }
  
  .boxPosition .boxItem.inProgress{
    
  }
  .boxPosition .boxItem.inProgress .title,
  .boxPosition .boxItem.inProgress,
  .boxPosition .boxItem.inProgress .content .status,
  .boxPosition .boxItem.inProgress i {
    color: #D90101;
  }
  
  .boxPosition .boxItem.problem .title,
  .boxPosition .boxItem.problem,
  .boxPosition .boxItem.problem .content .status,
  .boxPosition .boxItem.problem i {
    color: #000000;
  }
  