.HeaderContainer{
    position: relative;
    height: 600px;
}
.SearchPriceContainer{
    height: 80px;
}
.headerSlider h1{
    font-weight: bold;
    margin-bottom: 30px;
    font-size: 48px !important;
}
.headerSlider h4{
    font-weight: bold;
    margin-top: 50px;
}
.section-download-app{
    text-align: left;
    margin-top: 15px;
}
.section-download-app .icon-app{
    width: 150px;
    float: left;
    margin-right: 20px;
    border: 1px solid #8a8a8a;
    border-radius: 8px;
    cursor: pointer;
}
.btn-search{
    font-weight: bold;
    height: 80px;
    border-radius: 0 8px 8px 0;
    font-size: 18px;
}
.btn-search i{
    margin-left: 7px;
}
.SearchPriceContainer label{
    color: #000;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 0;
}
.SearchPriceContainer input.form-control{
    border: none;
    box-shadow: none;
    background-color: transparent;
    padding: 0 15px 0 0;
}
.SearchPriceContainer .input-group-addon{
    border: none;
    box-shadow: none;
    background-color: transparent;
    color: #D90101;
}

.SearchPriceContainer .pinMap{
    width: calc(50% - 110px);
}
.SearchPriceContainer .pinBtn{
    width: 220px;
}

@media only screen and (max-width: 600px) {
    .pinMap{
        width: 100%;
        border-left: none;
    }
    .pinBtn{
        width: 100%;
    }
    .SearchPriceContainer{
        height: auto;
    }    
    .btn-search{
        font-weight: bold;
        height: 80px;
        border-radius: 8px;
        font-size: 18px;
    }
}