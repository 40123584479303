.container {
  display: flex;
  font-size: 20px;
  align-items: center;
}

.container i {
  margin-right: 5px;
}

.red i {
  color: #DE0201;
}

.grey i {
  color: rgba(0, 0, 0, 0.2);
}

.black i {
  color: #000000;
}