.additionalItem {
    position: relative;
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin: 0 0 15px;
    padding-bottom: 15px;
}
.additionalItem:last-child {
    border-bottom: none;
}
.additionalItem .imageStyle{
    position: absolute;
    left: 0;
    top: 3px;
    font-size: 16px;
}
.additionalItem .title{
    float: left;    
    font-weight: bold;
    width: calc(100% - 40px);
    font-size: 20px;
    line-height: 1;
}
.additionalItem .title small{
    width: 100%;
    margin-top: 3px;
    float: left;
    color: #808080;
    font-weight: normal;
    font-size: 18px;
    line-height: 1;
}
.additionalItem.selected .title small{
    color: #19AA19;
    font-style: normal;
}
.additionalItem .rightIconStyle{
    float: left;
    color: #B3B3B3;
    width: 40px;
    text-align: right;
    font-size: 22px;
    padding-top:7px;
}
.additionalItem.disabled{
    cursor: not-allowed;
}
.additionalItem.disabled .title{
    
}
.additionalItem.disabled .title small{
    font-style: normal;
}