.radioItem {
    position: relative;
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin: 0 0 15px;
    padding-bottom: 15px;
    cursor: pointer;
}
.radioItem:last-child {
    border-bottom: none;
}
.radioItem .imageStyle{
    position: absolute;
    left: 0;
    top: 3px;
    font-size: 16px;
}
.radioItem .title{
    float: left;    
    font-weight: bold;
    width: calc(100% - 40px);
}
.radioItem .title small{
    width: 100%;
    margin-top: 3px;
    float: left;
    color: #666666;
    font-weight: normal;
    font-style: italic;
}
.radioItem.selected .title small{
    color: #333333;
    font-style: normal;
}
.radioItem .rightIconStyle{
    float: left;
    color: #B3B3B3;
    width: 40px;
    text-align: right;
    font-size: 22px;
    padding-top:7px;
}
.radioItem.disabled{
    cursor: not-allowed;
}
.radioItem.disabled .title{
    color: #999999;
}
.radioItem.disabled .title small{
    color: #B3B3B3;
    font-style: normal;
}