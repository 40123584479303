body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-btn:hover, .ant-btn:focus {
  color: #00000080;
  border-color: #00000065;
}

.ant-btn i {
  font-size: 16px;
}

a:hover {
  color: #00000080; 
}

.anticon {
  vertical-align: -4px;
}

.ant-avatar > .anticon {
  vertical-align: -8px;
}

.ant-popover {
  z-index: 1;
}

.ant-statistic-content {
  font-family: 'db_heavent', 'Open Sans' , sans-serif !important;
}

.ant-modal-header {
  padding: 16px 24px;
}

.ant-modal-title {
  font-size: 20px;
}

.ant-modal-close-x {
  font-size: 18px;
}

.ant-modal-body {
  padding: 10px 24px;
}