.toolContainer {
  position: absolute;
  background-color: #fff;
  width: 400px;
  height: calc(100vh - 110px);
  z-index: 5;
  left: 840px;
  padding: 20px;
  box-shadow: 4px 0 10px -4px rgba(0, 0, 0, 0.2);
}

.btnClose {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 14px;
  cursor: pointer;
  color: #888888;
  font-size: 24px;
}

.header {
  line-height: 1.2;
}

.title {
  font-size: 24px;
  font-weight: bold;
}

.subTitle {
  font-size: 20px;
}

.list:not(:last-child) {
  border-bottom: 1px solid #E3E3E3;
}

.list:first-child {
  margin-top: 10px;
}

.list:last-child {
  margin-bottom: 20px;
}