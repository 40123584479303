.table {
  border-collapse: collapse;
  margin-top: 10px;
  width: 100%;
}

.table tbody tr:nth-child(odd) {
  background: #f9f9f9;
}

.table td,
.table th {
  padding: 10px 4px;
}

.table tbody td {
  font-size: 20px;
}

.table tr {
  border-bottom: 1px solid #e7e7e7;
}

.table thead th {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
}

.table thead th:first-child {
  border-left: none;
  padding-left: 10px;
}

.btnDelete {
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  color: #d90101;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border-radius: 50%;
}

.btnDelete:hover {
  background-color: #eeeeee;
}

.column1 {
  text-align: center;
  width: 10%;
}

.column2 {
  width: 20%;
}

.column3 {
  width: 70%;
}

.profileList {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.profileImage {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  position: relative;
  border: 1px solid #eeeeee;
}

.profileContent {
  font-size: 18px;
  font-weight: bold;
}

.plSmall {
  padding-left: 20px !important;
}

.selectBox {
  max-width: 300px;
  font-size: 18px;
}

@media(max-width: 767px) {
  .table {
    max-width: 767px;
    overflow: auto;
  }

  .column1 {
    width: 200px;
  }
  
  .column2 {
    width: 500px;
  }
  
  .column3 {
    width: 500px;
  }
}
