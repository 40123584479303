.truckListItem {
    position: relative;
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin: 0 0 15px;
    padding-bottom: 10px;
    cursor: pointer;
}
.truckListItem:last-child {
    border-bottom: none;
}
.truckListItem .imageStyle{
    float: left;
    width: 60px;
}
.truckListItem .imageStyle img{
    width: 50px;
}
.truckListItem .title{
    float: left;    
    font-weight: bold;
    width: calc(100% - 90px);
    font-size: 20px;
    line-height: 1;
}
.truckListItem .title small{
    width: 100%;
    margin-top: 3px;
    float: left;
    color: #666666;
    font-weight: normal;
    font-size: 16px;
    line-height: 1;
}
.truckListItem.selected .title small{
    color: #333333;
    font-style: normal;
}
.truckListItem .rightIconStyle{
    float: right;
    color: #B3B3B3;
    width: 20px;
    text-align: right;
    font-size: 22px;
    padding-top:20px;
}
.truckListItem.disabled{
    cursor: not-allowed;
}
.truckListItem.disabled .title{
    color: #999999;
}
.truckListItem.disabled .title small{
    color: #B3B3B3;
    font-style: normal;
}