.ServiceListItem {
    position: relative;
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
    padding-bottom: 15px;
    cursor: pointer;
}
.ServiceListItem.no-border {
    border-bottom: none;
    padding-bottom: 0; 
}
.ServiceListItem.right-border {
    border-right: 1px solid #ddd;;
    padding-right: 10px; 
}
.ServiceListItem:last-child:before {
    border-left: none;
}
.ServiceListItem .iconStyle{
    position: inherit;
    left: unset;
    top: unset;
    float: left;
    color: #000000;
    width: 40px;
    text-align: left;
    font-size: 22px;
    padding-top: 7px;
}
.ServiceListItem.credit .iconStyle{
    margin-right:10px ;
}
.ServiceListItem .title{
    float: left;
    width: calc(100% - 60px);
    font-weight: bold;
    padding-left: 5px;
    font-size: 20px;
    line-height: 1;
}
.ServiceListItem.img .title{
    float: left;
    width: calc(100% - 90px);
    font-weight: bold;
}
.ServiceListItem.credit .title{
    float: left;
    width: calc(100% - 90px);
    font-weight: bold;
}
.ServiceListItem .title small{
    width: 100%;
    margin-top: 3px;
    float: left;
    color: #B3B3B3;
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 1;
}
.ServiceListItem.selected .title small{
    color: #000000;
    font-style: normal;
}
.ServiceListItem .rightIconStyle{
    float: right;
    color: #B3B3B3;
    width: 20px;
    text-align: right;
    font-size: 18px;
    padding-top:7px;
}
.ServiceListItem .imgesStyle{
    float: left;
    width: 65px;
    font-size: 18px;
}
.ServiceListItem .imgesStyle img{
    width: 100%;
}
.ServiceListItem.selected,
.ServiceListItem.selected .iconStyle,
.ServiceListItem.selected .title small,
.ServiceListItem.selected .rightIconStyle
{
    color: #d90101;
}