.wrapper {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #E3E3E3;
  border-bottom: 1px solid #E3E3E3;
  padding: 10px 0px;
  cursor: pointer;
}

.header {
  font-size: 18px;
  display: flex;
  flex-direction: row;
  line-height: 1.2;
}

.left {
  flex: 1;
}

.right {
  color: #888888;
  display: flex;
  flex-direction: row;
  align-content: center;
}

.right i {
  margin-left: 5px;
}