.modalPromotion {
  max-width: 700px;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
}

@media(max-width: 768px) {
  .modalPromotion {
    position: relative;
  }
}