.wrapper404{
    margin-top: 250px;
    text-align: center;
}
.wrapper404 h1{
   font-size: 28px;
   margin-bottom: 0;
}
.wrapper404 p{
   font-size: 28px;
   font-weight: normal;
}
.wrapper404 button{
    margin-top: 10px;
    width: 150px;
    position: absolute;
    bottom: 50px;
    left: calc(50% - 140px);
}
.img404{
    width: 250px;
    margin: 0 auto 30px;
}

@media only screen and (max-width: 767px) {
    .wrapper404{
        margin-top: 30%;
        text-align: center;
    }
    .img404{
        width: 250px;
        margin: 0 auto 30px;
    }
}