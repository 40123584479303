.btn-link.active.focus, 
.btn-link.active:focus, 
.btn-link:active.focus, 
.btn-link:active:focus, 
.open>.dropdown-toggle.btn-link.focus, 
.open>.dropdown-toggle.btn-link:focus, 
.open>.dropdown-toggle.btn-link:hover,
.btn-link.active.focus, 
.btn-link.active:focus, 
.btn-link.focus, .btn:active.focus, 
.btn-link:active:focus, 
.btn-link:focus,.btn.active.focus, 
.btn-link.active:focus, 
.btn-link.focus{
    color:#000;
    border: none;
    outline: none;
    background-color: transparent;
    text-decoration: none;
}
.btn-link:active.hover, 
.btn-link.active:hover, 
.btn-link:active:hover, 
.btn-link:active:hover, 
.btn-link:hover{
    color:#333;
    border: none;
    outline: none;
    background-color: transparent;
    text-decoration: none;
}