.pointItem {
    position: relative;
    width: calc(100% - 40px);
    margin-left: 40px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
    padding-bottom: 15px;
    cursor: pointer;
}
.pointItem:before {
    width: 1px;
    height: 40px;
    position: absolute;
    left: -23px;
    border-left: 2px dotted #B3B3B3;
    top: 28px;
}
.pointItem:last-child:before {
    border-left: none;
}
.iconStyle{
    position: absolute;
    left: -30px;
    top: 3px;
    font-size: 16px;
}
.pointItem .title{
    float: left;
    width: calc(100% - 40px);
    font-size: 20px;
    line-height: 1;
}
.pointItem .title small{
    width: 100%;
    margin-top: 3px;
    float: left;
    color: #B3B3B3;
    font-style: italic;
    font-size: 16px;
    line-height: 1;
}
.pointItem.selected .title small{
    color: #000000;
    font-style: normal;
}
.rightIconStyle{
    float: left;
    color: #B3B3B3;
    width: 40px;
    text-align: right;
    font-size: 18px;
    padding-top:7px;
}