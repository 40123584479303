.dateTime-wrapper{
    margin: 20px 0;
}
.rdtPicker{
    position: relative;
    background-color: #fff;
    border-radius: 4px;
    font-size: 20px;
    color: #000000;
    width: 100%;
    box-sizing: content-box;
    z-index: 100;
    border-bottom: 1px solid #efefef
}
.rdtPicker .rdtDays table,
.rdtPicker .rdtMonths table,
.rdtPicker .rdtYears table,
.rdtPicker .rdtTime table{
    display: block;
    margin: 4px;
}
.rdtPicker .rdtDays table thead,
.rdtPicker .rdtMonths table thead ,
.rdtPicker .rdtYears table thead  {
    display: block;
    margin: 8px 0 3px;
}
.rdtPicker .rdtDays table thead tr,
.rdtPicker .rdtMonths table thead tr ,
.rdtPicker .rdtYears table thead tr {
    display: flex;
    flex-wrap: wrap;
}
.rdtPicker table thead th{
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
    text-align: center;
    text-transform: uppercase;
    font-size: 1em;
}
.rdtPicker .rdtDays table tbody,
.rdtPicker .rdtMonths table tbody,
.rdtPicker .rdtYears table tbody,
.rdtPicker .rdtTime table tbody{
    display: block;
}
.rdtPicker .rdtDays table tbody tr,
.rdtPicker .rdtMonths table tbody tr,
.rdtPicker .rdtYears table tbody tr,
.rdtPicker .rdtTime table tbody tr{
    display: flex;
    flex-wrap: wrap;
}
.rdtPicker .rdtDays table tbody tr td{
    width: 14.28571429%;
}
.rdtPicker .rdtDays table tbody tr td {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 0.9em;
}
.rdtPicker .rdtMonths table tbody tr td {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
}
.rdtPicker .rdtYears table tbody tr td {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
}
.rdtPicker .rdtDays table td,
.rdtPicker .rdtDays table th {
    padding: 0;    
    align-items: center;
}
.rdtPicker table tbody tr td.rdtDisabled, 
.rdtPicker table tbody tr td.next, 
.rdtPicker table tbody tr td.prev{ 
    color: #dedede;
}
.rdtPicker table thead th.dow{ 
    color: #999999;
    font-size: 16px;
    padding-top: 10px;
}
.rdtPicker  thead {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #efefef;
    min-height: 32px;
    padding: 4px;
}
.rdtPicker thead span,
.rdtPicker thead span {
    font-size: 24px;
}
.rdtPicker .rdtDays .rdtToday {
   color: #19AA19;
}
.rdtPicker .rdtMonths table tbody tr td{
    width: 25%;
    height: 60px;
}
.rdtPicker .rdtDays .rdtActive,
.rdtPicker .rdtMonths .rdtActive,
.rdtPicker .rdtYears .rdtActive   {
    background-color: #19AA19;
    color: #fff !important;
    font-weight: 400;
}
.rdtPicker table tbody tr td.rdtActive {
    color: #19AA19;
    font-weight: 400; 
}
.rdtPicker .rdtYears table tbody tr td {
    width: 25%;
    height: 60px;
}
.rdtPicker .rdtDays tfoot{
    width: 100%;
    text-align: center;
    margin: 5px;
    display: block;
    border-top: 1px solid #efefef;
}
.rdtPicker tr,
.rdtPicker td{
    width: 100%;
    text-align: center;
    display: block;
}
.rdtPicker .rdtPrev ,
.rdtPicker .rdtNext{
    display: flex;
    text-align: center;
    min-height: 32px;
    padding: 4px;
    font-size: 18px; 
    background: none;
    border: 0;
    width: 32px;
    height: 32px;
    max-width: 32px;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
}
.rdtPicker .rdtSwitch{
    display: flex;
    min-height: 32px;
    padding: 4px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    background-color: whitesmoke;
    border-radius: 4px;
    cursor: pointer;
}
.rdtPicker .rdtTimeToggle {
    flex: 1;
    font-size: 40px;
    color: #19AA19;
    text-align: center;
    margin: 0;
    padding-right: 5px !important;
}
.rdtPicker .rdtTime table tbody tr td{
    width: 100%;
    padding: 30px 0;
}
.rdtPicker .rdtTime .rdtCounters{
    display: block;
}
.rdtPicker .rdtTime .rdtCounters .rdtCounter{
    align-items: center;
    justify-content: center;
    float: left;
    width: 40%;
}
.rdtPicker .rdtTime .rdtCounters .rdtCounterSeparator{
    align-items: center;
    justify-content: center;
    float: left;
    width: 20%;    
    padding-top: 40px;
    font-size: 30px;
}
.rdtPicker .rdtTime .rdtBtn{
    font-size: 18px;
    height: 35px;
    width: 100%;
    display: block;
    padding-top: 10px;
    cursor: pointer;
}
.rdtPicker .rdtTime .rdtCount{
    font-size: 40px;
    color: #19AA19;
}