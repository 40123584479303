.modalPromotion {
  width: 100%;
  max-width: 640px;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

@media(max-width: 767px) {
  .modalPromotion {
    padding: 10px;
    padding-top: 20px;
    position: relative;
    top: 0%;
    transform: translate(-50%, 0%);
  }
}