.creditCard{
    position: relative;
    width: 230px;
    height: 127px;
    margin: 0 auto;
    border-radius: 6px;
    color: #ffffff;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#5b5b5b+1,0a0a0a+54,0a0a0a+99 */
    background: #5b5b5b; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #5b5b5b 1%, #0a0a0a 54%, #0a0a0a 99%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #5b5b5b 1%,#0a0a0a 54%,#0a0a0a 99%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #5b5b5b 1%,#0a0a0a 54%,#0a0a0a 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5b5b5b', endColorstr='#0a0a0a',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.creditCard.Visa{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4b6cb7+1,182848+99 */
    background: #4b6cb7; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #4b6cb7 1%, #182848 99%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #4b6cb7 1%,#182848 99%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #4b6cb7 1%,#182848 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4b6cb7', endColorstr='#182848',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.creditCard.MasterCard{
    color: #000000;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#eff2f3+1,8d9eab+99 */
    background: #eff2f3; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #eff2f3 1%, #8d9eab 99%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #eff2f3 1%,#8d9eab 99%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #eff2f3 1%,#8d9eab 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eff2f3', endColorstr='#8d9eab',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.creditCard.JCB{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#008eed+1,0054af+99 */
    background: #008eed; /* Old browsers */
    background: -moz-linear-gradient(-45deg, #008eed 1%, #0054af 99%); /* FF3.6-15 */
    background: -webkit-linear-gradient(-45deg, #008eed 1%,#0054af 99%); /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #008eed 1%,#0054af 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#008eed', endColorstr='#0054af',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}