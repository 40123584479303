.profileImage {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  position: relative;
  border: 1px solid #eeeeee;
  align-items: center;
  justify-content: center;
  background-color: #eeeeee;
}

.profileImage img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  display: block;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
}

.profileImage i {
  color: #ffffff;
}
