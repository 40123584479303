.btn-link.active.focus, 
.btn-link.active:focus, 
.btn-link:active.focus, 
.btn-link:active:focus, 
.open>.dropdown-toggle.btn-link.focus, 
.open>.dropdown-toggle.btn-link:focus, 
.open>.dropdown-toggle.btn-link:hover,
.btn-link.active.focus, 
.btn-link.active:focus, 
.btn-link.focus, .btn:active.focus, 
.btn-link:active:focus, 
.btn-link:focus,.btn.active.focus, 
.btn-link.active:focus, 
.btn-link.focus{
    color:#000;
    border: none;
    outline: none;
    background-color: transparent;
    text-decoration: none;
}
.btn-link:active.hover, 
.btn-link.active:hover, 
.btn-link:active:hover, 
.btn-link:active:hover, 
.btn-link:hover{
    color:#333;
    border: none;
    outline: none;
    background-color: transparent;
    text-decoration: none;
}
.ModalCardFlashDeals_modalPromotion__3rlbV {
  width: 100%;
  max-width: 640px;
  position: absolute;
  left: 50%;
  top: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

@media(max-width: 767px) {
  .ModalCardFlashDeals_modalPromotion__3rlbV {
    padding: 10px;
    padding-top: 20px;
    position: relative;
    top: 0%;
    -webkit-transform: translate(-50%, 0%);
            transform: translate(-50%, 0%);
  }
}
.pointItem {
    position: relative;
    width: calc(100% - 40px);
    margin-left: 40px;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
    padding-bottom: 15px;
    cursor: pointer;
}
.pointItem:before {
    width: 1px;
    height: 40px;
    position: absolute;
    left: -23px;
    border-left: 2px dotted #B3B3B3;
    top: 28px;
}
.pointItem:last-child:before {
    border-left: none;
}
.iconStyle{
    position: absolute;
    left: -30px;
    top: 3px;
    font-size: 16px;
}
.pointItem .title{
    float: left;
    width: calc(100% - 40px);
    font-size: 20px;
    line-height: 1;
}
.pointItem .title small{
    width: 100%;
    margin-top: 3px;
    float: left;
    color: #B3B3B3;
    font-style: italic;
    font-size: 16px;
    line-height: 1;
}
.pointItem.selected .title small{
    color: #000000;
    font-style: normal;
}
.rightIconStyle{
    float: left;
    color: #B3B3B3;
    width: 40px;
    text-align: right;
    font-size: 18px;
    padding-top:7px;
}
.radioItem {
    position: relative;
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin: 0 0 15px;
    padding-bottom: 15px;
    cursor: pointer;
}
.radioItem:last-child {
    border-bottom: none;
}
.radioItem .imageStyle{
    position: absolute;
    left: 0;
    top: 3px;
    font-size: 16px;
}
.radioItem .title{
    float: left;    
    font-weight: bold;
    width: calc(100% - 40px);
}
.radioItem .title small{
    width: 100%;
    margin-top: 3px;
    float: left;
    color: #666666;
    font-weight: normal;
    font-style: italic;
}
.radioItem.selected .title small{
    color: #333333;
    font-style: normal;
}
.radioItem .rightIconStyle{
    float: left;
    color: #B3B3B3;
    width: 40px;
    text-align: right;
    font-size: 22px;
    padding-top:7px;
}
.radioItem.disabled{
    cursor: not-allowed;
}
.radioItem.disabled .title{
    color: #999999;
}
.radioItem.disabled .title small{
    color: #B3B3B3;
    font-style: normal;
}
.ServiceListItem {
    position: relative;
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
    padding-bottom: 15px;
    cursor: pointer;
}
.ServiceListItem.no-border {
    border-bottom: none;
    padding-bottom: 0; 
}
.ServiceListItem.right-border {
    border-right: 1px solid #ddd;;
    padding-right: 10px; 
}
.ServiceListItem:last-child:before {
    border-left: none;
}
.ServiceListItem .iconStyle{
    position: inherit;
    left: unset;
    top: unset;
    float: left;
    color: #000000;
    width: 40px;
    text-align: left;
    font-size: 22px;
    padding-top: 7px;
}
.ServiceListItem.credit .iconStyle{
    margin-right:10px ;
}
.ServiceListItem .title{
    float: left;
    width: calc(100% - 60px);
    font-weight: bold;
    padding-left: 5px;
    font-size: 20px;
    line-height: 1;
}
.ServiceListItem.img .title{
    float: left;
    width: calc(100% - 90px);
    font-weight: bold;
}
.ServiceListItem.credit .title{
    float: left;
    width: calc(100% - 90px);
    font-weight: bold;
}
.ServiceListItem .title small{
    width: 100%;
    margin-top: 3px;
    float: left;
    color: #B3B3B3;
    font-style: italic;
    font-weight: normal;
    font-size: 16px;
    line-height: 1;
}
.ServiceListItem.selected .title small{
    color: #000000;
    font-style: normal;
}
.ServiceListItem .rightIconStyle{
    float: right;
    color: #B3B3B3;
    width: 20px;
    text-align: right;
    font-size: 18px;
    padding-top:7px;
}
.ServiceListItem .imgesStyle{
    float: left;
    width: 65px;
    font-size: 18px;
}
.ServiceListItem .imgesStyle img{
    width: 100%;
}
.ServiceListItem.selected,
.ServiceListItem.selected .iconStyle,
.ServiceListItem.selected .title small,
.ServiceListItem.selected .rightIconStyle
{
    color: #d90101;
}
.additionalItem {
    position: relative;
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin: 0 0 15px;
    padding-bottom: 15px;
}
.additionalItem:last-child {
    border-bottom: none;
}
.additionalItem .imageStyle{
    position: absolute;
    left: 0;
    top: 3px;
    font-size: 16px;
}
.additionalItem .title{
    float: left;    
    font-weight: bold;
    width: calc(100% - 40px);
    font-size: 20px;
    line-height: 1;
}
.additionalItem .title small{
    width: 100%;
    margin-top: 3px;
    float: left;
    color: #808080;
    font-weight: normal;
    font-size: 18px;
    line-height: 1;
}
.additionalItem.selected .title small{
    color: #19AA19;
    font-style: normal;
}
.additionalItem .rightIconStyle{
    float: left;
    color: #B3B3B3;
    width: 40px;
    text-align: right;
    font-size: 22px;
    padding-top:7px;
}
.additionalItem.disabled{
    cursor: not-allowed;
}
.additionalItem.disabled .title{
    
}
.additionalItem.disabled .title small{
    font-style: normal;
}
.truckListItem {
    position: relative;
    width: 100%;
    border-bottom: 1px solid #ddd;
    margin: 0 0 15px;
    padding-bottom: 10px;
    cursor: pointer;
}
.truckListItem:last-child {
    border-bottom: none;
}
.truckListItem .imageStyle{
    float: left;
    width: 60px;
}
.truckListItem .imageStyle img{
    width: 50px;
}
.truckListItem .title{
    float: left;    
    font-weight: bold;
    width: calc(100% - 90px);
    font-size: 20px;
    line-height: 1;
}
.truckListItem .title small{
    width: 100%;
    margin-top: 3px;
    float: left;
    color: #666666;
    font-weight: normal;
    font-size: 16px;
    line-height: 1;
}
.truckListItem.selected .title small{
    color: #333333;
    font-style: normal;
}
.truckListItem .rightIconStyle{
    float: right;
    color: #B3B3B3;
    width: 20px;
    text-align: right;
    font-size: 22px;
    padding-top:20px;
}
.truckListItem.disabled{
    cursor: not-allowed;
}
.truckListItem.disabled .title{
    color: #999999;
}
.truckListItem.disabled .title small{
    color: #B3B3B3;
    font-style: normal;
}
.gm-style-iw > button {
  display: none !important;
}
.ReactModal_modalPromotion__1Ft7g {
  max-width: 700px;
  position: absolute;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
}

@media(max-width: 768px) {
  .ReactModal_modalPromotion__1Ft7g {
    position: relative;
  }
}
.creditCard{
    position: relative;
    width: 230px;
    height: 127px;
    margin: 0 auto;
    border-radius: 6px;
    color: #ffffff;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#5b5b5b+1,0a0a0a+54,0a0a0a+99 */
    background: #5b5b5b; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #5b5b5b 1%,#0a0a0a 54%,#0a0a0a 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5b5b5b', endColorstr='#0a0a0a',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.creditCard.Visa{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4b6cb7+1,182848+99 */
    background: #4b6cb7; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #4b6cb7 1%,#182848 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4b6cb7', endColorstr='#182848',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.creditCard.MasterCard{
    color: #000000;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#eff2f3+1,8d9eab+99 */
    background: #eff2f3; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #eff2f3 1%,#8d9eab 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eff2f3', endColorstr='#8d9eab',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.creditCard.JCB{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#008eed+1,0054af+99 */
    background: #008eed; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #008eed 1%,#0054af 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#008eed', endColorstr='#0054af',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.creditCard{
    position: relative;
    width: 230px;
    height: 127px;
    margin: 20px auto 10px;
    border-radius: 6px;
    color: #ffffff;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#5b5b5b+1,0a0a0a+54,0a0a0a+99 */
    background: #5b5b5b; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #5b5b5b 1%,#0a0a0a 54%,#0a0a0a 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#5b5b5b', endColorstr='#0a0a0a',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.creditCard.Visa{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#4b6cb7+1,182848+99 */
    background: #4b6cb7; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #4b6cb7 1%,#182848 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#4b6cb7', endColorstr='#182848',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.creditCard.MasterCard{
    color: #000000;
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#eff2f3+1,8d9eab+99 */
    background: #eff2f3; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #eff2f3 1%,#8d9eab 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#eff2f3', endColorstr='#8d9eab',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.creditCard.JCB{
    /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#008eed+1,0054af+99 */
    background: #008eed; /* Old browsers */ /* FF3.6-15 */ /* Chrome10-25,Safari5.1-6 */
    background: linear-gradient(135deg, #008eed 1%,#0054af 99%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#008eed', endColorstr='#0054af',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
.dateTime-wrapper{
    margin: 20px 0;
}
.rdtPicker{
    position: relative;
    background-color: #fff;
    border-radius: 4px;
    font-size: 20px;
    color: #000000;
    width: 100%;
    box-sizing: content-box;
    z-index: 100;
    border-bottom: 1px solid #efefef
}
.rdtPicker .rdtDays table,
.rdtPicker .rdtMonths table,
.rdtPicker .rdtYears table,
.rdtPicker .rdtTime table{
    display: block;
    margin: 4px;
}
.rdtPicker .rdtDays table thead,
.rdtPicker .rdtMonths table thead ,
.rdtPicker .rdtYears table thead  {
    display: block;
    margin: 8px 0 3px;
}
.rdtPicker .rdtDays table thead tr,
.rdtPicker .rdtMonths table thead tr ,
.rdtPicker .rdtYears table thead tr {
    display: flex;
    flex-wrap: wrap;
}
.rdtPicker table thead th{
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1;
    text-align: center;
    text-transform: uppercase;
    font-size: 1em;
}
.rdtPicker .rdtDays table tbody,
.rdtPicker .rdtMonths table tbody,
.rdtPicker .rdtYears table tbody,
.rdtPicker .rdtTime table tbody{
    display: block;
}
.rdtPicker .rdtDays table tbody tr,
.rdtPicker .rdtMonths table tbody tr,
.rdtPicker .rdtYears table tbody tr,
.rdtPicker .rdtTime table tbody tr{
    display: flex;
    flex-wrap: wrap;
}
.rdtPicker .rdtDays table tbody tr td{
    width: 14.28571429%;
}
.rdtPicker .rdtDays table tbody tr td {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 0.9em;
}
.rdtPicker .rdtMonths table tbody tr td {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
}
.rdtPicker .rdtYears table tbody tr td {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
}
.rdtPicker .rdtDays table td,
.rdtPicker .rdtDays table th {
    padding: 0;    
    align-items: center;
}
.rdtPicker table tbody tr td.rdtDisabled, 
.rdtPicker table tbody tr td.next, 
.rdtPicker table tbody tr td.prev{ 
    color: #dedede;
}
.rdtPicker table thead th.dow{ 
    color: #999999;
    font-size: 16px;
    padding-top: 10px;
}
.rdtPicker  thead {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #efefef;
    min-height: 32px;
    padding: 4px;
}
.rdtPicker thead span,
.rdtPicker thead span {
    font-size: 24px;
}
.rdtPicker .rdtDays .rdtToday {
   color: #19AA19;
}
.rdtPicker .rdtMonths table tbody tr td{
    width: 25%;
    height: 60px;
}
.rdtPicker .rdtDays .rdtActive,
.rdtPicker .rdtMonths .rdtActive,
.rdtPicker .rdtYears .rdtActive   {
    background-color: #19AA19;
    color: #fff !important;
    font-weight: 400;
}
.rdtPicker table tbody tr td.rdtActive {
    color: #19AA19;
    font-weight: 400; 
}
.rdtPicker .rdtYears table tbody tr td {
    width: 25%;
    height: 60px;
}
.rdtPicker .rdtDays tfoot{
    width: 100%;
    text-align: center;
    margin: 5px;
    display: block;
    border-top: 1px solid #efefef;
}
.rdtPicker tr,
.rdtPicker td{
    width: 100%;
    text-align: center;
    display: block;
}
.rdtPicker .rdtPrev ,
.rdtPicker .rdtNext{
    display: flex;
    text-align: center;
    min-height: 32px;
    padding: 4px;
    font-size: 18px; 
    background: none;
    border: 0;
    width: 32px;
    height: 32px;
    max-width: 32px;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
}
.rdtPicker .rdtSwitch{
    display: flex;
    min-height: 32px;
    padding: 4px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    background-color: whitesmoke;
    border-radius: 4px;
    cursor: pointer;
}
.rdtPicker .rdtTimeToggle {
    flex: 1 1;
    font-size: 40px;
    color: #19AA19;
    text-align: center;
    margin: 0;
    padding-right: 5px !important;
}
.rdtPicker .rdtTime table tbody tr td{
    width: 100%;
    padding: 30px 0;
}
.rdtPicker .rdtTime .rdtCounters{
    display: block;
}
.rdtPicker .rdtTime .rdtCounters .rdtCounter{
    align-items: center;
    justify-content: center;
    float: left;
    width: 40%;
}
.rdtPicker .rdtTime .rdtCounters .rdtCounterSeparator{
    align-items: center;
    justify-content: center;
    float: left;
    width: 20%;    
    padding-top: 40px;
    font-size: 30px;
}
.rdtPicker .rdtTime .rdtBtn{
    font-size: 18px;
    height: 35px;
    width: 100%;
    display: block;
    padding-top: 10px;
    cursor: pointer;
}
.rdtPicker .rdtTime .rdtCount{
    font-size: 40px;
    color: #19AA19;
}
.dateTime-wrapper{
    margin: 20px 0;
}
.rdtPicker{
    position: relative;
    background-color: #fff;
    border-radius: 4px;
    font-size: 20px;
    color: #000000;
    width: 100%;
    box-sizing: content-box;
    z-index: 100;
    border-bottom: 1px solid #efefef
}
.rdtPicker .rdtDays table,
.rdtPicker .rdtMonths table,
.rdtPicker .rdtYears table,
.rdtPicker .rdtTime table{
    display: block;
    margin: 4px;
}
.rdtPicker .rdtDays table thead,
.rdtPicker .rdtMonths table thead ,
.rdtPicker .rdtYears table thead  {
    display: block;
    margin: 8px 0 3px;
}
.rdtPicker .rdtDays table thead tr,
.rdtPicker .rdtMonths table thead tr ,
.rdtPicker .rdtYears table thead tr {
    display: flex;
    flex-wrap: wrap;
}
.rdtPicker table thead th{
    color: #333333;
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1 1;
    text-align: center;
    text-transform: uppercase;
    font-size: 1em;
}
.rdtPicker .rdtDays table tbody,
.rdtPicker .rdtMonths table tbody,
.rdtPicker .rdtYears table tbody,
.rdtPicker .rdtTime table tbody{
    display: block;
}
.rdtPicker .rdtDays table tbody tr,
.rdtPicker .rdtMonths table tbody tr,
.rdtPicker .rdtYears table tbody tr,
.rdtPicker .rdtTime table tbody tr{
    display: flex;
    flex-wrap: wrap;
}
.rdtPicker .rdtDays table tbody tr td{
    width: 14.28571429%;
}
.rdtPicker .rdtDays table tbody tr td {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37px;
    border-radius: 50%;
    cursor: pointer;
    font-size: 0.9em;
}
.rdtPicker .rdtMonths table tbody tr td {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
}
.rdtPicker .rdtYears table tbody tr td {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 37px;
    border-radius: 4px;
    cursor: pointer;
    font-size: 0.9em;
}
.rdtPicker .rdtDays table td,
.rdtPicker .rdtDays table th {
    padding: 0;    
    align-items: center;
}
.rdtPicker table tbody tr td.rdtDisabled, 
.rdtPicker table tbody tr td.next, 
.rdtPicker table tbody tr td.prev{ 
    color: #dedede;
}
.rdtPicker table thead th.dow{ 
    color: #999999;
    font-size: 16px;
    padding-top: 10px;
}
.rdtPicker  thead {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #efefef;
    min-height: 32px;
    padding: 4px;
}
.rdtPicker thead span,
.rdtPicker thead span {
    font-size: 24px;
}
.rdtPicker .rdtDays .rdtToday {
   color: #19AA19;
}
.rdtPicker .rdtMonths table tbody tr td{
    width: 25%;
    height: 60px;
}
.rdtPicker .rdtDays .rdtActive,
.rdtPicker .rdtMonths .rdtActive,
.rdtPicker .rdtYears .rdtActive   {
    background-color: #19AA19;
    color: #fff !important;
    font-weight: 400;
}
.rdtPicker table tbody tr td.rdtActive {
    color: #19AA19;
    font-weight: 400; 
}
.rdtPicker .rdtYears table tbody tr td {
    width: 25%;
    height: 60px;
}
.rdtPicker .rdtDays tfoot{
    width: 100%;
    text-align: center;
    margin: 5px;
    display: block;
    border-top: 1px solid #efefef;
}
.rdtPicker tr,
.rdtPicker td{
    width: 100%;
    text-align: center;
    display: block;
}
.rdtPicker .rdtPrev ,
.rdtPicker .rdtNext{
    display: flex;
    text-align: center;
    min-height: 32px;
    padding: 4px;
    font-size: 18px; 
    background: none;
    border: 0;
    width: 32px;
    height: 32px;
    max-width: 32px;
    border-radius: 4px;
    outline: none;
    cursor: pointer;
}
.rdtPicker .rdtSwitch{
    display: flex;
    min-height: 32px;
    padding: 4px;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
    background-color: whitesmoke;
    border-radius: 4px;
    cursor: pointer;
}
.rdtPicker .rdtTimeToggle {
    flex: 1 1;
    font-size: 40px;
    color: #19AA19;
    text-align: center;
    margin: 0;
    padding-right: 5px !important;
}
.rdtPicker .rdtTime table tbody tr td{
    width: 100%;
    padding: 30px 0;
}
.rdtPicker .rdtTime .rdtCounters{
    display: block;
}
.rdtPicker .rdtTime .rdtCounters .rdtCounter{
    align-items: center;
    justify-content: center;
    float: left;
    width: 40%;
}
.rdtPicker .rdtTime .rdtCounters .rdtCounterSeparator{
    align-items: center;
    justify-content: center;
    float: left;
    width: 20%;    
    padding-top: 40px;
    font-size: 30px;
}
.rdtPicker .rdtTime .rdtBtn{
    font-size: 18px;
    height: 35px;
    width: 100%;
    display: block;
    padding-top: 10px;
    cursor: pointer;
}
.rdtPicker .rdtTime .rdtCount{
    font-size: 40px;
    color: #19AA19;
}
.ReactModalSidebar_modalPromotion__2ZFkc {
  position: relative;
}
.ToolDetailWrapper{
    background-color: #fff;
    z-index: 5;
    left: 0;
    position: absolute;
    box-shadow: 4px 0 10px -4px rgba(0, 0, 0, 0.2);
}
.subToolWrapper::-webkit-scrollbar{
    width: 8px;
}
.subToolWrapper::-webkit-scrollbar-thumb{
    background-color: #ccc;
    border-radius: 8px;
}
.subToolWrapper::-webkit-scrollbar-track{
    background-color: transparent; 
}
#mainShipmentDetail .subDetailWrapper{
    position: relative;
    background-color: #fff;
    float: left;
    width: 420px;
    border-right: 1px solid #E3E3E3;
    box-shadow:none;
    overflow-x: hidden;
    overflow-y: auto;
}
#mainShipmentDetail .subDetailWrapper.open{
    left: 420px;
}
.MapDetailWrapper{
    position:fixed;
    right: 0;
    width: calc(100% - 840px);
    height: calc(100vh - 100px);
}

/* boxPosition */
  .boxPosition{
    margin-top: 20px;
    padding-top: 20px;
  }
  .boxPosition .boxItem {
      position: relative;
      padding-left: 30px;
      border-left: 2px dotted #E3E3E3;
      margin-bottom: 20px;
      margin-left: 20px;
  }
  .boxPosition .boxItem:last-child{
    border-left: 2px dotted transparent;
  }
  .boxPosition .boxItem i {
      position: absolute;
      top: -15px;
      left: -10px;
      font-size: 16px;
      color: #8B8B8B;
  }
  .boxPosition .boxItem .content{
    padding: 0;
    position: relative;
    top: -20px;
  }
  .boxPosition .boxItem .content .title{
    
  }
  .boxPosition .boxItem .content .title.boderBottom{
    border-bottom: 1px solid #E3E3E3;
    padding-bottom: 15px;
    margin-bottom: 10px;
  }
  .boxPosition .boxItem .content .status{
    color: #8B8B8B;
    font-size: .9em;
  }
  .boxPosition .boxItem .content .detail{
  }
  .boxPosition.tracking .boxItem .content .detail{
      margin-top: 15px;
  }
  .boxPosition .boxItem .content .detail .img_wrapper{
      width: 90px;
      float: left;
      text-align: center;
      margin-right: 10px;
  }
  .boxPosition .boxItem .content .detail .img{
      box-sizing: content-box;
      overflow: hidden;
      position: relative;
      width: 90px;
      height: 90px;
      margin-bottom: 10px;    
      cursor: pointer;
  }
  
  .boxPosition .boxItem .content .detail .img img{
      object-fit: cover;
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: -9999px;
      bottom: -9999px;
      left: -9999px;
      right: -9999px;
      margin: auto;
  }
  
  .boxPosition .boxItem.inProgress{
    
  }
  .boxPosition .boxItem.inProgress .title,
  .boxPosition .boxItem.inProgress,
  .boxPosition .boxItem.inProgress .content .status,
  .boxPosition .boxItem.inProgress i {
    color: #D90101;
  }
  
  .boxPosition .boxItem.problem .title,
  .boxPosition .boxItem.problem,
  .boxPosition .boxItem.problem .content .status,
  .boxPosition .boxItem.problem i {
    color: #000000;
  }

  .ril__image, .ril__imagePrev, .ril__imageNext{
    background-color: #fff;
  }

  div.ril__image, div.ril__imagePrev, div.ril__imageNext{
    background-color: transparent;
  }
.ListArrow_wrapper__2xKMj {
  display: flex;
  flex-direction: column;
  border-top: 1px solid #E3E3E3;
  border-bottom: 1px solid #E3E3E3;
  padding: 10px 0px;
  cursor: pointer;
}

.ListArrow_header__23sL8 {
  font-size: 18px;
  display: flex;
  flex-direction: row;
  line-height: 1.2;
}

.ListArrow_left__2eHu7 {
  flex: 1 1;
}

.ListArrow_right__2Hjc_ {
  color: #888888;
  display: flex;
  flex-direction: row;
  align-content: center;
}

.ListArrow_right__2Hjc_ i {
  margin-left: 5px;
}
.FavoriteDriverDetail_container__1V5Vo {
  display: flex;
  font-size: 20px;
  align-items: center;
}

.FavoriteDriverDetail_container__1V5Vo i {
  margin-right: 5px;
}

.FavoriteDriverDetail_red__2Cmt- i {
  color: #DE0201;
}

.FavoriteDriverDetail_grey__d4ZeF i {
  color: rgba(0, 0, 0, 0.2);
}

.FavoriteDriverDetail_black__z1qmN i {
  color: #000000;
}
.ManageDriver_toolContainer__3Ziqa {
  position: absolute;
  background-color: #fff;
  width: 400px;
  height: calc(100vh - 110px);
  z-index: 5;
  left: 840px;
  padding: 20px;
  box-shadow: 4px 0 10px -4px rgba(0, 0, 0, 0.2);
}

.ManageDriver_btnClose__U1IOt {
  position: absolute;
  top: 0;
  right: 0;
  padding: 8px 14px;
  cursor: pointer;
  color: #888888;
  font-size: 24px;
}

.ManageDriver_header__21D_2 {
  line-height: 1.2;
}

.ManageDriver_title__17WJo {
  font-size: 24px;
  font-weight: bold;
}

.ManageDriver_subTitle__3ArWv {
  font-size: 20px;
}

.ManageDriver_list__2DQTm:not(:last-child) {
  border-bottom: 1px solid #E3E3E3;
}

.ManageDriver_list__2DQTm:first-child {
  margin-top: 10px;
}

.ManageDriver_list__2DQTm:last-child {
  margin-bottom: 20px;
}
.ToolDetailWrapper {
	background-color: #fff;
	z-index: 5;
	left: 0;
	position: absolute;
	box-shadow: 4px 0 10px -4px rgba(0, 0, 0, 0.2);
}
.subToolWrapper::-webkit-scrollbar {
	width: 8px;
}
.subToolWrapper::-webkit-scrollbar-thumb {
	background-color: #ccc;
	border-radius: 8px;
}
.subToolWrapper::-webkit-scrollbar-track {
	background-color: transparent;
}
#mainTrackingDetail .subDetailWrapper {
	position: relative;
	background-color: #fff;
	float: left;
	width: 420px;
	border-right: 1px solid #e3e3e3;
	box-shadow: none;
	overflow-x: hidden;
	overflow-y: auto;
}
#mainTrackingDetail .subDetailWrapper.open {
	left: 420px;
}
.MapDetailWrapper {
	position: fixed;
	right: 0;
	width: calc(100% - 840px);
	height: calc(100vh - 100px);
}
.map-mobile-size {
	width: 100%;
	height: 260px;
}

/* boxPosition */
.boxPosition {
	margin-top: 20px;
	padding-top: 20px;
}
.boxPosition .boxItem {
	position: relative;
	padding-left: 30px;
	border-left: 2px dotted #e3e3e3;
	margin-bottom: 20px;
	margin-left: 20px;
}
.boxPosition .boxItem:last-child {
	border-left: 2px dotted transparent;
}
.boxPosition .boxItem i {
	position: absolute;
	top: -15px;
	left: -10px;
	font-size: 16px;
	color: #8b8b8b;
}
.boxPosition .boxItem .content {
	padding: 0;
	position: relative;
	top: -20px;
}
.boxPosition .boxItem .content .title {
}
.boxPosition .boxItem .content .title.boderBottom {
	border-bottom: 1px solid #e3e3e3;
	padding-bottom: 15px;
	margin-bottom: 10px;
}
.boxPosition .boxItem .content .status {
	color: #8b8b8b;
	font-size: 0.9em;
}
.boxPosition .boxItem .content .detail {
}
.boxPosition.tracking .boxItem .content .detail {
	margin-top: 15px;
}
.boxPosition .boxItem .content .detail .img_wrapper {
	width: 90px;
	float: left;
	text-align: center;
	margin-right: 10px;
}
.boxPosition .boxItem .content .detail .img {
	box-sizing: content-box;
	overflow: hidden;
	position: relative;
	width: 90px;
	height: 90px;
	margin-bottom: 10px;
	cursor: pointer;
}
.boxPosition .boxItem .content .detail .img img {
	object-fit: cover;
	position: absolute;
	display: block;
	width: 100%;
	height: 100%;
	top: -9999px;
	bottom: -9999px;
	left: -9999px;
	right: -9999px;
	margin: auto;
}

.boxPosition .boxItem.inProgress {
}
.boxPosition .boxItem.inProgress .title,
.boxPosition .boxItem.inProgress,
.boxPosition .boxItem.inProgress .content .status,
.boxPosition .boxItem.inProgress i {
	color: #d90101;
}

.boxPosition .boxItem.problem .title,
.boxPosition .boxItem.problem,
.boxPosition .boxItem.problem .content .status,
.boxPosition .boxItem.problem i {
	color: #000000;
}

.statusContainer {
	border: 1px solid #e3e3e3;
	border-radius: 5px;
	padding: 10px 10px 10px 30px;
}

.backgroundTracking {
    background-image: url(/static/media/circle.c5d5a675.svg);
    background-repeat: no-repeat;
    background-position: top 280px center;
    background-size: 100%;
}
.ToolDetailWrapper{
    background-color: #fff;
    z-index: 5;
    left: 0;
    position: absolute;
    box-shadow: 4px 0 10px -4px rgba(0, 0, 0, 0.2);
}
.subToolWrapper::-webkit-scrollbar{
    width: 8px;
}
.subToolWrapper::-webkit-scrollbar-thumb{
    background-color: #ccc;
    border-radius: 8px;
}
.subToolWrapper::-webkit-scrollbar-track{
    background-color: transparent; 
}
#mainShipmentDetail .subDetailWrapper{
    position: relative;
    background-color: #fff;
    float: left;
    width: 420px;
    border-right: 1px solid #E3E3E3;
    box-shadow:none;
    overflow-x: hidden;
    overflow-y: auto;
}
#mainShipmentDetail .subDetailWrapper.open{
    left: 420px;
}
.MapDetailWrapper{
    position:fixed;
    right: 0;
    width: calc(100% - 840px);
    height: calc(100vh - 100px);
}

/* boxPosition */
  .boxPosition{
    margin-top: 20px;
    padding-top: 20px;
  }
  .boxPosition .boxItem {
      position: relative;
      padding-left: 30px;
      border-left: 2px dotted #E3E3E3;
      margin-bottom: 20px;
      margin-left: 20px;
  }
  .boxPosition .boxItem:last-child{
    border-left: 2px dotted transparent;
  }
  .boxPosition .boxItem i {
      position: absolute;
      top: -15px;
      left: -10px;
      font-size: 16px;
      color: #8B8B8B;
  }
  .boxPosition .boxItem .content{
    padding: 0;
    position: relative;
    top: -20px;
  }
  .boxPosition .boxItem .content .title{
    
  }
  .boxPosition .boxItem .content .title.boderBottom{
    border-bottom: 1px solid #E3E3E3;
    padding-bottom: 15px;
    margin-bottom: 10px;
  }
  .boxPosition .boxItem .content .status{
    color: #8B8B8B;
    font-size: .9em;
  }
  .boxPosition .boxItem .content .detail{
  }
  .boxPosition.tracking .boxItem .content .detail{
      margin-top: 15px;
  }
  .boxPosition .boxItem .content .detail .img_wrapper{
      width: 90px;
      float: left;
      text-align: center;
      margin-right: 10px;
  }
  .boxPosition .boxItem .content .detail .img{
      box-sizing: content-box;
      overflow: hidden;
      position: relative;
      width: 90px;
      height: 90px;
      margin-bottom: 10px;
  }
  
  .boxPosition .boxItem .content .detail .img img{
      object-fit: cover;
      position: absolute;
      display: block;
      width: 100%;
      height: 100%;
      top: -9999px;
      bottom: -9999px;
      left: -9999px;
      right: -9999px;
      margin: auto;
  }
  
  .boxPosition .boxItem.inProgress{
    
  }
  .boxPosition .boxItem.inProgress .title,
  .boxPosition .boxItem.inProgress,
  .boxPosition .boxItem.inProgress .content .status,
  .boxPosition .boxItem.inProgress i {
    color: #D90101;
  }
  
  .boxPosition .boxItem.problem .title,
  .boxPosition .boxItem.problem,
  .boxPosition .boxItem.problem .content .status,
  .boxPosition .boxItem.problem i {
    color: #000000;
  }
  
.wrapper404{
    margin-top: 250px;
    text-align: center;
}
.wrapper404 h1{
   font-size: 28px;
   margin-bottom: 0;
}
.wrapper404 p{
   font-size: 28px;
   font-weight: normal;
}
.wrapper404 button{
    margin-top: 10px;
    width: 150px;
    position: absolute;
    bottom: 50px;
    left: calc(50% - 140px);
}
.img404{
    width: 250px;
    margin: 0 auto 30px;
}

@media only screen and (max-width: 767px) {
    .wrapper404{
        margin-top: 30%;
        text-align: center;
    }
    .img404{
        width: 250px;
        margin: 0 auto 30px;
    }
}
.backgroundCirclenotiApp {
  background-image: url(/static/media/circle.c5d5a675.svg);
  background-repeat: no-repeat;
  background-position: top 100px center;
  background-size: 200%;
  text-align: center;
}
.HeaderContainer{
    position: relative;
    height: 600px;
}
.SearchPriceContainer{
    height: 80px;
}
.headerSlider h1{
    font-weight: bold;
    margin-bottom: 30px;
    font-size: 48px !important;
}
.headerSlider h4{
    font-weight: bold;
    margin-top: 50px;
}
.section-download-app{
    text-align: left;
    margin-top: 15px;
}
.section-download-app .icon-app{
    width: 150px;
    float: left;
    margin-right: 20px;
    border: 1px solid #8a8a8a;
    border-radius: 8px;
    cursor: pointer;
}
.btn-search{
    font-weight: bold;
    height: 80px;
    border-radius: 0 8px 8px 0;
    font-size: 18px;
}
.btn-search i{
    margin-left: 7px;
}
.SearchPriceContainer label{
    color: #000;
    font-weight: bold;
    font-size: 18px;
    margin-top: 10px;
    margin-bottom: 0;
}
.SearchPriceContainer input.form-control{
    border: none;
    box-shadow: none;
    background-color: transparent;
    padding: 0 15px 0 0;
}
.SearchPriceContainer .input-group-addon{
    border: none;
    box-shadow: none;
    background-color: transparent;
    color: #D90101;
}

.SearchPriceContainer .pinMap{
    width: calc(50% - 110px);
}
.SearchPriceContainer .pinBtn{
    width: 220px;
}

@media only screen and (max-width: 600px) {
    .pinMap{
        width: 100%;
        border-left: none;
    }
    .pinBtn{
        width: 100%;
    }
    .SearchPriceContainer{
        height: auto;
    }    
    .btn-search{
        font-weight: bold;
        height: 80px;
        border-radius: 8px;
        font-size: 18px;
    }
}
.Avatar_profileImage__TTHHS {
  display: flex;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  position: relative;
  border: 1px solid #eeeeee;
  align-items: center;
  justify-content: center;
  background-color: #eeeeee;
}

.Avatar_profileImage__TTHHS img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  display: block;
  top: -9999px;
  bottom: -9999px;
  left: -9999px;
  right: -9999px;
  margin: auto;
}

.Avatar_profileImage__TTHHS i {
  color: #ffffff;
}

.FavoriteDriver_table__2U1V2 {
  border-collapse: collapse;
  margin-top: 10px;
  width: 100%;
}

.FavoriteDriver_table__2U1V2 tbody tr:nth-child(odd) {
  background: #f9f9f9;
}

.FavoriteDriver_table__2U1V2 td,
.FavoriteDriver_table__2U1V2 th {
  padding: 10px 4px;
}

.FavoriteDriver_table__2U1V2 tbody td {
  font-size: 20px;
}

.FavoriteDriver_table__2U1V2 tr {
  border-bottom: 1px solid #e7e7e7;
}

.FavoriteDriver_table__2U1V2 thead th {
  font-size: 20px;
  font-weight: bold;
  color: #000000;
}

.FavoriteDriver_table__2U1V2 thead th:first-child {
  border-left: none;
  padding-left: 10px;
}

.FavoriteDriver_btnDelete__2bMMH {
  width: 40px;
  height: 40px;
  background-color: transparent;
  border: none;
  font-size: 18px;
  color: #d90101;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  outline: none;
  border-radius: 50%;
}

.FavoriteDriver_btnDelete__2bMMH:hover {
  background-color: #eeeeee;
}

.FavoriteDriver_column1__cnYZs {
  text-align: center;
  width: 10%;
}

.FavoriteDriver_column2__3NfYb {
  width: 20%;
}

.FavoriteDriver_column3__3hzSy {
  width: 70%;
}

.FavoriteDriver_profileList__2gQGQ {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.FavoriteDriver_profileImage__5icN0 {
  display: inline-block;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 20px;
  position: relative;
  border: 1px solid #eeeeee;
}

.FavoriteDriver_profileContent__2HEEg {
  font-size: 18px;
  font-weight: bold;
}

.FavoriteDriver_plSmall__3ajTW {
  padding-left: 20px !important;
}

.FavoriteDriver_selectBox__zRP-f {
  max-width: 300px;
  font-size: 18px;
}

@media(max-width: 767px) {
  .FavoriteDriver_table__2U1V2 {
    max-width: 767px;
    overflow: auto;
  }

  .FavoriteDriver_column1__cnYZs {
    width: 200px;
  }
  
  .FavoriteDriver_column2__3NfYb {
    width: 500px;
  }
  
  .FavoriteDriver_column3__3hzSy {
    width: 500px;
  }
}

.PDPA_wrapper__3y9wU {
	position: fixed;
	left: 0;
	bottom: 0;
	font-size: 14px;
	background-color: rgba(38, 34, 34, 0.95);
	z-index: 999;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 0px 26px;
	color: #ffffff;
	align-items: center;
	font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
		Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
}

.PDPA_content__37u_A {
	flex: 1 1;
	margin: 15px;
	display: flex;
	flex-direction: column;
}

.PDPA_link__2i8CZ {
	font-size: inherit;
	color: inherit;
	text-decoration: underline;
	display: inline-block;
}

.PDPA_link__2i8CZ:hover,
.PDPA_link__2i8CZ:active,
.PDPA_link__2i8CZ:focus {
	color: #d90101;
	text-decoration: underline;
}

.PDPA_button__3Nsx6 {
	background-color: #d90101;
	border: 2px solid transparent;
	border-radius: 0px;
	box-shadow: none;
	color: white;
	cursor: pointer;
	padding: 6px 12px;
	margin: 15px;
	display: block;
	min-width: 140px;
	font-size: inherit;
	font-weight: 700;
	text-align: center;
	white-space: nowrap;
}

@media (max-width: 767px) {
	.PDPA_wrapper__3y9wU {
		padding: 0px 16px;
		flex-direction: column;
		align-items: flex-start;
	}

	.PDPA_button__3Nsx6 {
		margin-top: 0;
	}
}

body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 20px;
  color: #000000;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.ant-btn:hover, .ant-btn:focus {
  color: #00000080;
  border-color: #00000065;
}

.ant-btn i {
  font-size: 16px;
}

a:hover {
  color: #00000080; 
}

.anticon {
  vertical-align: -4px;
}

.ant-avatar > .anticon {
  vertical-align: -8px;
}

.ant-popover {
  z-index: 1;
}

.ant-statistic-content {
  font-family: 'db_heavent', 'Open Sans' , sans-serif !important;
}

.ant-modal-header {
  padding: 16px 24px;
}

.ant-modal-title {
  font-size: 20px;
}

.ant-modal-close-x {
  font-size: 18px;
}

.ant-modal-body {
  padding: 10px 24px;
}
